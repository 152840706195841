import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Redirect } from "react-router-dom";
import { login } from "../../actions/user";
import login_back from '../../static/img/login_back.png'
import { back_color } from '../../configs/constants';
import login_img from '../../static/img/login.png'

const _ = require('micro-dash');

function Login() {
    const dispatch = useDispatch();
    const [username, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const token = useSelector((state) => state.user.token);

    const userChange = (event) => {
        setUser(event.target.value)
    }
    const pwdChange = (event) => {
        setPassword(event.target.value)
    }
    const submit = () => {
        if (username === '') {
            alert('账号不能未空')
        } else if (password === '') {
            alert('密码不能未空')
        } else {
            dispatch(login(username, password))
        }
    }
    if (!_.isNil(token)) {
        //聊天登录
        // const user_info=useSelector((state)=>JSON.parse(state.user.user));
        // emit('socket_login', {user_id:user_info.id});
        return <Redirect to={'/'} />;
    }
    // backgroundImage:`url(${login_back})`
    return (
        <div style={{ minHeight: '100vh',backgroundImage:`url(${login_back})`, backgroundColor: '#ec7437', backgroundSize: '100% 100%' }}>
            <div style={{ position: 'fixed', left: '2%', top: '3%' }}>
                <img src={login_img} alt='' style={{ width: '60vw' }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{position: 'absolute', right: '10vw',top:'25vh'}}>
                    <div style={{color:'#fff',textAlign:'center',paddingBottom:'5%'}}>
                        <div style={{fontSize:16}}>博槐互联网科技-麻麻来了智能棋牌</div>
                        <div style={{fontSize:38,paddingBottom:10,fontWeight:'bold'}}>欢迎注册麻麻来了</div>
                        <div style={{fontSize:12,color:'rgba(255,255,255,0.5)'}}>麻麻来了全国加盟智能品牌</div>
                    </div>
                    <div style={{ background: '#fff', padding: 60,  borderRadius: 8,width:'20vw' }}>
                        {/* <div style={{textAlign:'center',paddingBottom:25,fontSize:30}}>登陆</div> */}
                        <div>
                            <TextField
                                required
                                id="outlined-required"
                                label="账号"
                                value={username}
                                style={{width:'100%'}}
                                onChange={userChange}
                            />
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <TextField
                                required
                                id="outlined-required1"
                                label="密码"
                                type='password'
                                value={password}
                                style={{width:'100%'}}
                                onChange={pwdChange}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button style={{ width: "100%", background: back_color,padding:10,fontSize:15 }} variant="contained" onClick={submit}>登陆</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 13, width: '80%', position: 'absolute', bottom: '2%', left: '10%', color: '#fff' }}>
                <a href="https://beian.miit.gov.cn/" style={{color: '#fff'}}>湘ICP备2023021782号-1</a>
                <div>湖南棋开必胜商业连锁有限公司</div>
            </div>
        </div>
    )
}
export default Login;